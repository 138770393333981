export class FormatDate { 
    constructor(date) {
      this.date = date;
    }

    formattedDate;
    weekday;
    time;
  
    format() {
        return this.formattedDate = new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(this.date);
  
      this.weekday = this.formattedDate.split(', ')[0];
      this.time = this.formattedDate.split(', ')[1];

      return this.formattedDate;
    }
  
}
