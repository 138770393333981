"use strict";
import User from './user.js';
import loginView from './views/loginView.js';
import taskView from './views/taskView.js';



let loginStatus;
let activeUser;


function controlLoginIn(user) {
	if (!user) return;
	activeUser = User.validateUser(user.username, user.password);
	if (activeUser) {
		loginStatus = true;
	} else {
		console.log('Login failed');
		loginStatus = false;
	}

	if (loginStatus) {
		loginView.loginSuccess();
		taskView.render(activeUser);
	}
}

function controlSignUp(user) {
	if (!user) return;
	activeUser = User.createUser(user.name, user.username, user.password);
	if (activeUser) {
		console.log('Signup successful');
		loginStatus = true;
	} else {
		console.log('Signup failed');
		loginStatus = false;
	}
	if (loginStatus) {
		loginView.loginSuccess();
		taskView.render(activeUser);
	}
}

function controlAddTask(taskObject) {
	if (!loginStatus && !activeUser) return;
	activeUser.addTask(taskObject);
	taskView.render(activeUser)
}


function controlEditTask(taskIndex, newTaskObject) {
	if (!loginStatus && !activeUser) return;
	if(!taskIndex) return;
	activeUser.editTask(taskIndex, newTaskObject);
	taskView.render(activeUser)
}

function controlDeleteTask(taskIndex) {
	if (!loginStatus && !activeUser) return;
	if(!taskIndex) return;
	activeUser.deleteTask(taskIndex);
	taskView.render(activeUser)
}

function controlCompleteTask(taskIndex) {
	if (!loginStatus && !activeUser) return;
	if(!taskIndex) return;
	activeUser.completeTask(taskIndex);
	taskView.render(activeUser)
}

function controlLogOut() {
	loginStatus = false;
	activeUser = null;
	taskView.render(activeUser)
}

function init() {
	loginView.addLoginHandler(controlLoginIn)
	loginView.addSignupHandler(controlSignUp)
	loginView.addLogoutHandler(controlLogOut);

	taskView.addNewTaskHandler(controlAddTask);
	taskView.addEditTaskHandler(controlEditTask);
	taskView.addCompleteHandler(controlCompleteTask);
}


init();