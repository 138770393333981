import {FormatDate} from "../helper.js";

class TaskView {
	_parentEl = document.querySelector(".container");
	_overlay = this._parentEl.nextElementSibling;
	_taskFormContainer;
	_editTaskFormContainer;


	constructor() {
		this.addEventListener();
	}

	render(user) {
		this._clear();
		this._parentEl.innerHTML = this._generateMarkup(user);
	}

	addNewTaskHandler(callback) {
		this._parentEl.addEventListener("click", (event) => {
			const addTaskBtn = event.target.closest(".add-task");
			if (!addTaskBtn) return;
			this._taskFormContainer = addTaskBtn.nextElementSibling;

			this._taskFormContainer.classList.toggle("hidden");
			this._overlay.classList.toggle("hidden");
			const form = this._taskFormContainer.querySelector(".task-form");
			if (form) {
				form.addEventListener("submit", (event) => {
					event.preventDefault();
					const taskObject = {
						title: form.querySelector("#taskTitle").value,
						description: form.querySelector("#taskDescription").value,
					};

					if (taskObject.title) {
						callback(taskObject);
					}
					this._overlay.classList.toggle("hidden");
				});
			}
		});
	}

	addEditTaskHandler(callback) {
		this._parentEl.addEventListener('click', (event) => {
			const editTaskBtn = event.target.closest('.edit-task-btn');
			if (editTaskBtn) {
				const taskItem = editTaskBtn.closest('.task-item');
				if (taskItem) {
					const taskIndex = taskItem.querySelector('.task-status').getAttribute('data-id');
					const taskTitle = taskItem.querySelector('.task-title').textContent;
					const taskDescription = taskItem.querySelector('.task-description').textContent;

					// Create a form element with a placeholder of the task title and description
					this._overlay.classList.toggle('hidden');
					this._editTaskFormContainer = document.createElement('div');
					this._editTaskFormContainer.classList.add('task-modal');
					this._editTaskFormContainer.innerHTML = `
                    <form class="task-form">
                        <h2 class="task-form__title">Edit Task</h2>
                        <input type="text" id="taskTitle" value="${taskTitle}" class="task-form__title-input" required>
                        <input type="text" id="taskDescription" value="${taskDescription}" class="task-form__description-input">
                        <button type="submit" class="btn task-form__btn-submit">Edit Task</button>
                    </form>`;
					this._parentEl.querySelector('.task-container').appendChild(this._editTaskFormContainer);

					// Add event listener to the form
					this._editTaskFormContainer.addEventListener('submit', (event) => {
						event.preventDefault();
						const taskObject = {
							newTitle: this._editTaskFormContainer.querySelector('#taskTitle').value,
							newDescription: this._editTaskFormContainer.querySelector('#taskDescription').value,
						};

						callback(taskIndex, taskObject);
						this._overlay.classList.add('hidden');
						this._editTaskFormContainer.remove();

					});
				}
			}
		});
	}


	addCompleteHandler(callback) {
		this._parentEl.addEventListener("change", (event) => {
			if (event.target.closest(".task-status")) {
				const taskIndex = event.target
					.closest(".task-status")
					.getAttribute("data-id");
				callback(taskIndex);
			}
		});
	}

	addEventListener() {
		this._overlay.addEventListener("click", (event) => {
			this._overlay.classList.add("hidden");
			if (this._taskFormContainer) {
				this._taskFormContainer.classList.add("hidden");
			}
			if (this._editTaskFormContainer) {
				console.log("I got here");
				this._editTaskFormContainer.remove(); // Remove the edit task form container
				this._editTaskFormContainer = null;
			}

		});
	}


	logout() {
		this._parentEl.innerHTML = "";
	}

	_clear() {
		this._parentEl.innerHTML = "";
	}

	_generateMarkup(User) {
		const formatDate = new FormatDate();
		if (!User) {
			return `
				<div class="head-container ">
			        <div class="head-text-container">
			            <h1 class="primary-heading">Stay Organized, <span>Stay Productive</span></h1>
			            <p className="hero-description">Taskly helps you manage your tasks efficiently, so you can focus on what
			                matters most. From personal to professional, conquer your to-do list with ease.</p>
			        </div>
	            </div>`;
		}
		return `
		<div class="logon">
            <div class="message-container ">
                <h2 class="message">Good Morning, <span class="name">${User.name.split(" ")[0]}</span></h2>
            </div>

            <div class="task-container ">
			<button type="submit" class="add-task btn--to-add"><span class="material-symbols-outlined">add</span></button>
			<div class="task-modal hidden">
			  <form id="taskForm" class="task-form">
				<input type="text" id="taskTitle" placeholder="Task Title" class="task-form__title-input" required>
				<input type="text" id="taskDescription" placeholder="Task Description" class="task-form__description-input">
				<button type="submit" class="btn--submit task-form__btn-submit">Submit</button>
			  </form>
			</div>

                <ul class="task-list">
                   ${User.tasks
			.map((task, index) => `<li class="task-item ${task.completed ? "task-item--done" : ""}">
                        <input type="checkbox" ${task.completed ? "checked" : ""} class="task-status" data-id="${index}">
                        <div class="task-content">
                            <h3 class="task-title">${task.title}</h3>
                            <p class="task-description">${task.description}</p>
                            <p class="task-due-date">${formatDate.format(task.createdDate)}</p>
                        </div>
                        <button class="btn edit-task-btn">Edit Task</button>
                    </li>`)
			.join("")}
                </ul>
            </div>
        </div>`;
	}
}

export default new TaskView();
