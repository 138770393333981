import Task from './task.js';
import allUsers from './allUsers.js';

class User {
	constructor(name, username, password) {
		this.name = name;
		this.username = username;
		this.password = password;
		this.tasks = [];
	}

	addTask(taskObject) {
		const { title, description } = taskObject;
		if (title && description) {
			const task = new Task(title, description);
			this.tasks.push(task);
		} else {
			console.error('Invalid task data.');
		}
	}

	completeTask(taskIndex) {
		if (taskIndex >= 0 && taskIndex < this.tasks.length) {
			this.tasks[taskIndex].complete();
		} else {
			console.error('Invalid task index.');
		}
	}

	deleteTask(taskIndex) {
		if (taskIndex >= 0 && taskIndex < this.tasks.length) {
			this.tasks.splice(taskIndex, 1);
		} else {
			console.error('Invalid task index.');
		}
	}

	editTask(taskIndex, newTaskObject) {
		if (taskIndex >= 0 && taskIndex < this.tasks.length) {
			this.tasks[taskIndex].edit(newTaskObject);
		} else {
			console.error('Invalid task index.');
		}
	}

	static validateUser(username, password) {
		console.log(allUsers);
		return allUsers.users.find(user => user.username === username && user.password === password);
	}

	static createUser(name, username, password) {
		// Check if the username already exists
		const existingUser = allUsers.users.find(user => user.username === username);
		if (existingUser) {
			console.log('User with this username already exists.');
			return null; // Returning null to indicate that user creation failed
		}

		// If the username is unique, create the new user
		const user = new User(name, username, password);
		allUsers.users.push(user);
		console.log(allUsers);
		return user;
	}

}

const object = [
    {
        "name": "John Doe",
        "username": "johndoe123",
        "password": "password123",
        "tasks": [
            {
                "title": "Task 1",
                "description": "Complete assignment"
            },
            {
                "title": "Task 2",
                "description": "Read a book"
            }
        ]
    },
    {
        "name": "Alice Johnson",
        "username": "alicej",
        "password": "securepwd",
        "tasks": [
            {
                "title": "Task 1",
                "description": "Attend meeting"
            },
            {
                "title": "Task 2",
                "description": "Prepare presentation"
            }
        ]
    },
    {
        "name": "Bob Smith",
        "username": "bobsmith",
        "password": "12345",
        "tasks": [
            {
                "title": "Task 1",
                "description": "Write report"
            }
        ]
    },
    {
        "name": "Eva Brown",
        "username": "evab",
        "password": "evapassword",
        "tasks": [
            {
                "title": "Task 1",
                "description": "Study for exam"
            },
            {
                "title": "Task 2",
                "description": "Complete homework"
            },
            {
                "title": "Make homework",
                "description": "If i get home, i will make some homework"
            },
        ]
    },
    {
        "name": "Michael White",
        "username": "michaelw",
        "password": "mypass123",
        "tasks": [
            {
                "title": "Task 1",
                "description": "Exercise"
            }
        ]
    }
];


object.forEach(obj => {
	const {name, username, password} = obj;
	const user = new User(name, username, password);
	if (obj.tasks) {
		obj.tasks.forEach((task, index) => {
			user.addTask(task);
			if (index === 1) {
				user.completeTask(1);
			}
		});
	}
	allUsers.users.push(user);
});

export default User;
