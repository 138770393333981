class LoginView {
	_parentEl = document.querySelector('.login-Signup-container');

	constructor() {
		this.addEventListener();
	}

	// Method to handle login button click
	addLoginHandler(callback) {
		this._parentEl.addEventListener('click', (event) => {
			event.preventDefault();

			const loginButton = event.target.closest('.btn--to-log-in');
			if (loginButton) {
				const userData = {
					username: this._parentEl.querySelector('#username').value,
					password: this._parentEl.querySelector('#password').value,
				};
				if (userData.username && userData.password) {
					callback(userData); // Pass user data to the provided callback function
				} else {
					console.log('Login failed');
				}
			}
		});
	}

	// Method to handle signup button click
	addSignupHandler(callback) {
		this._parentEl.addEventListener('click', (event) => {
			event.preventDefault();

			const signupButton = event.target.closest('.btn--to-sign-up');
			if (signupButton) {
				const userData = {
					name: this._parentEl.querySelector('#name').value,
					username: this._parentEl.querySelector('#username').value,
					password: this._parentEl.querySelector('#password').value,
				};
				if (userData.name && userData.username && userData.password) {
					callback(userData); // Pass user data to the provided callback function
				} else {
					console.log('Signup failed');
				}
			}
		});
	}
	
	// Method to handle logout button click
	addLogoutHandler(callback) {
		this._parentEl.addEventListener('click', (event) => {
			event.preventDefault();
			if (event.target.closest('.btn--log-out')) {
				callback();
				this._logout();
			}
		});
	}

	// Method to handle successful login
	loginSuccess() {
		this._clear();
		this._parentEl.insertAdjacentHTML("afterbegin", this._generateMarkup('loggedIn'));
	}
	
	// Method to handle logout
	_logout() {
	    this._clear();
	    this._parentEl.insertAdjacentHTML("afterbegin", this._generateMarkup('LoggedOut'));
	    // Add your logout logic here
	}

	// Method to _clear the parent element content
	_clear() {
		this._parentEl.innerHTML = '';
	}

	// Method to generate markup based on the mode (login, signup, or logout)
	addEventListener() {
		this._parentEl.addEventListener('click', (event) => {
			const loginBtn = event.target.closest('.btn--log-in');
			const signupBtn = event.target.closest('.btn--sign-up');

			if (loginBtn) {
				this._clear();
				this._parentEl.insertAdjacentHTML("afterbegin", this._generateMarkup('login'));
			} else if (signupBtn) {
				this._clear();
				this._parentEl.insertAdjacentHTML("afterbegin", this._generateMarkup('signup'));
			}
		});
	}

	// Method to generate markup based on the mode (login, signup, or logout)
	_generateMarkup(mode) {
		if (mode === 'login') {
			return `
	        <div class="login--container">
	            <div>
	                <input id="username" placeholder="username" value="johndoe123" type="text">
	            </div>
	            <div>
	                <input id="password" placeholder="********" value="password123" type="password">
	            </div>
	            <button type="submit" class="btn btn--to-log-in">Login</button>
	        </div>`;
		} else if (mode === 'signup') {
			return `
	        <div class="register--container">
	            <div>
	                <input id="name" placeholder="name" type="text">
	            </div>
	            <div>
	                <input id="username" placeholder="username" type="text">
	            </div>
	            <div>
	                <input id="password" placeholder="********" type="password">
	            </div>
	            <button type="submit" class="btn btn--to-sign-up">Sign Up</button>
	        </div>`;
		}  else if (mode === 'loggedIn') {
			return `<span class="material-symbols-outlined btn--log-out">Logout</span>`;
		}
		else if (mode === 'LoggedOut') {
			return `
			 <div class="login-Signup-container">
	            <div>
	                <button class="btn btn--sign-up">Sign up</button>
	                <button class="btn btn--log-in">Log in</button>
	            </div>
        	</div>
			`
			
		}
	}
}

export default new LoginView();

