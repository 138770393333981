class Task {
	constructor(title, description) {
		this.title = title;
		this.description = description;
		this.completed = false;
		this.createdDate = new Date();
	}

	complete() {
		this.completed = true;
	}

	edit(newTaskObject) {
		const {newTitle, newDescription = this.description} = newTaskObject;
		this.title = newTitle;
		this.description = newDescription;
	}
}



export default Task;
